.experience {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.experience:hover {
  transform: translateY(-7px);
}

.experience__description {
  margin-top: 1em;
  text-align: left;
}

.experience__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.experience__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.experience .link--icon {
  margin-left: 0.5em;
}

/* ken added 20240328 */

.experience__company {
  margin: 0.8em 0;
  font-size: 0.8rem;
}

.experience__job {
  margin: 0.5em 0;
  font-size: 0.6rem;
}

.experience__tasks {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin: 1.2em 0;
}

.experience__tasks-item {
  margin: 0.5em;
  /* font-weight: 500; */
  font-size: 0.8rem;
  text-align: left;
  /* color: var(--clr-fg-alt); */
}
